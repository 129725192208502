<template>
  <PageLoader
    v-if="pageLoading"
    :loading="pageLoading">
  </PageLoader>

  <div v-else class="container_margin">
    <div class="row">
      <div class="col col-md-9 col-lg-9 col-xl-9">
        <h5 class="font-weight-bold">Client Details Update Form</h5>
      </div>

      <div class="col-6 col-md-3 col-lg-3 col-xl-3">
        <button @click="editRole" class="btn btn-primary w-100 float-right">
          {{ "Update Changes" }}
        </button>
      </div>
    </div>

    <div
      class="card border-0 shadow mt-3 p-3"
      :style="{ 'min-height': isDropdownOpen ? '430px' : 'auto' }"
    >
      <h5 class="mb-3 font-weight-bold td-underline">Basic Details</h5>

      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2">Manager Name</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.manager_name"
              type="text"
              id="manager_name"
              name="manager_name"
              placeholder="Manager Name"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('manager_name')"
              >{{ errors.first("manager_name") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Contact Number</span
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.extra_info.contact_number"
              type="text"
              id="contact_number"
              name="contact_number"
              placeholder="Contact Number"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contact_number')"
              >{{ errors.first("contact_number") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2">Expiry Date</span>
          </div>

          <div class="card-text my-1">
            <b-form-datepicker
              id="example-datepicker"
              v-model="result.expiry_date"
              calendar-width="100%"
              :min="minDate"
              placeholder="Choose a Date"
            ></b-form-datepicker>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2">Email</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.manager_email"
              type="email"
              id="manager_email"
              name="manager_email"
              placeholder="Email"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('manager_email')"
              >{{ errors.first("manager_email") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2">Company Name</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.extra_info.company_name"
              type="text"
              id="company_name"
              name="company_name"
              placeholder="Company Name"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('company_name')"
              >{{ errors.first("company_name") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2">Label(s)</span>
          </div>

          <div class="col-12 col-md-12 col-xl-12">
            <div class="row">
              <div
                id="clientEditLabel"
                class="col-10 col-md-10 col-lg-11 align-self-center mb-2"
              >
                <multiselect
                  name="label"
                  v-validate="'required'"
                  maxHeight="122"
                  optionHeight="20"
                  v-model="result.labels"
                  :options="labelsList"
                  placeholder="Choose Label(s)"
                  @select="AddData"
                  @remove="removeData"
                  :multiple="true"
                  :close-on-select="false"
                  :searchable="false"
                  selectLabel=""
                  deselectLabel="Click to remove"
                  :limit="2"
                  @open="onDropdownOpen"
                  @close="onDropdownClose"
                ></multiselect>
              </div>

              <div class="col-10 align-self-center">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('label')"
                  >{{ errors.first("label") }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2">Address</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="address_1"
              placeholder="Address Line 1"
              type="text"
              id="address_1"
              name="address_1"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('address_1')"
              >{{ errors.first("address_1") }}</span
            >

            <b-form-input
              class="input-address"
              autocorrect="off"
              autocomplete="off"
              v-model="address_2"
              placeholder="Address Line 2"
              type="text"
              id="address_2"
              name="address_2"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('address_2')"
              >{{ errors.first("address_2") }}</span
            >

            <b-form-input
              class="input-address"
              autocorrect="off"
              autocomplete="off"
              v-model="address_3"
              placeholder="Address Line 3"
              type="text"
              id="address_3"
              name="address_3"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('address_3')"
              >{{ errors.first("address_3") }}</span
            >
          </div>

          <!-- <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2">Password</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.manager_password"
              type="password"
              id="password"
              name="password"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('password')">{{
              errors.first("password")
            }}</span>
          </div> -->
        </div>
      </div>
    </div>

    <div class="card border-0 shadow mt-3 p-3">
      <h5 class="mb-3 font-weight-bold td-underline">Law Features Credits</h5>
      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2"
              >Case Law Search</span
            >
            <small style="display: block" class="font-12"
              >Credits: Case Law Search</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.statement"
              type="text"
              id="case_law"
              name="case_law"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('case_law')">{{
              errors.first("case_law")
            }}</span>
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Jurisdiction Summary</span
            >
            <small style="display: block" class="font-12"
              >Credits: Case Law Search - Case Summary</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.jurisdiction_summarizing"
              type="text"
              id="jurisdiction_summarize"
              name="jurisdiction_summarize"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('jurisdiction_summarize')"
              >{{ errors.first("jurisdiction_summarize") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legislation Search</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legislation Search</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.legislation"
              type="text"
              id="legislation"
              name="legislation"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('legislation')"
              >{{ errors.first("legislation") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legislation Summary</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legislation Summary</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.legislation_summarizing"
              type="text"
              id="legislation_summarizing"
              name="legislation_summarizing"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('legislation_summarizing')"
              >{{ errors.first("legislation_summarizing") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Case Law Summarizer</span
            >
            <small style="display: block" class="font-12"
              >Credits: Case Law Summarizer</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.case_summarize"
              type="text"
              id="case_summarize"
              name="case_summarize"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('case_summarize')"
              >{{ errors.first("case_summarize") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Contract Review</span
            >
            <small style="display: block" class="font-12"
              >Credits: Contract Review</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.contract_review"
              type="text"
              id="contract_review"
              name="contract_review"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contract_review')"
              >{{ errors.first("contract_review") }}</span
            >
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2"
              >Contract Improvement</span
            >
            <small style="display: block" class="font-12"
              >Credits: Contract Improvement</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.contract_improvments"
              type="text"
              id="contract_improvments"
              name="contract_improvments"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contract_improvments')"
              >{{ errors.first("contract_improvments") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2">LawBot</span>
            <small style="display: block" class="font-12"
              >Credits: All LawBots</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.qbot_questions"
              type="text"
              id="qbot_questions"
              name="qbot_questions"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('qbot_questions')"
              >{{ errors.first("qbot_questions") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2">Legal Writer</span>
            <small style="display: block" class="font-12"
              >Credits: Legal Writer</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.lod"
              type="text"
              id="lod"
              name="lod"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('lod')">{{
              errors.first("lod")
            }}</span>
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legal Writer Summarizer</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legal Writer - Summary</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.summarize_lod"
              type="text"
              id="summarize_lod"
              name="summarize_lod"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('summarize_lod')"
              >{{ errors.first("summarize_lod") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legal Research</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legal Research</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.legal_argument"
              type="text"
              id="legal_argument"
              name="legal_argument"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('legal_argument')"
              >{{ errors.first("legal_argument") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legal Research Document Upload</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legal Research - Document Upload</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.legal_argument_documents"
              type="text"
              id="legal_argument_documents"
              name="legal_argument_documents"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('legal_argument_documents')"
              >{{ errors.first("legal_argument_documents") }}</span
            >
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2"
              >Legal Research Case Summarizer</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legal Research - Case Summarize</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.legal_argument_summarize_discussion_case"
              type="text"
              id="legal_argument_summarize_discussion_case"
              name="legal_argument_summarize_discussion_case"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('legal_argument_summarize_discussion_case')"
              >{{
                errors.first("legal_argument_summarize_discussion_case")
              }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legal Research Document Summarizer</span
            >
            <small style="display: block" class="font-12"
              >Credits: Legal Research - Document Summarize</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.legal_argument_summarize_document"
              type="text"
              id="legal_argument_summarize_document"
              name="legal_argument_summarize_document"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('legal_argument_summarize_document')"
              >{{ errors.first("legal_argument_summarize_document") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legal AI Pre-Trial Copilot</span
            >
            <small style="display: block" class="font-12"
              >Credits: Pre-Trial</small
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.les"
              type="text"
              id="les_pretrial"
              name="les_pretrial"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('les_pretrial')"
              >{{ errors.first("les_pretrial") }}</span
            >
          </div>

          <div class="card-text my-1 mt-3">
            <span for="fname" class="font-weight-bold my-2"
              >Legal AI Trial Copilot</span
            >
            <small style="display: block" class="font-12">Credits: Trial</small>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.les_trial"
              type="text"
              id="les_trial"
              name="les_trial"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('les_trial')"
              >{{ errors.first("les_trial") }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 shadow mt-3 p-3">
      <h5 class="mb-3 font-weight-bold td-underline">Project & Plan</h5>

      <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2"
              >Project Credits</span
            >
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.domains_quota"
              type="text"
              id="domains_quota"
              name="domains_quota"
              v-validate="'required|min_value:1'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('domains_quota')"
              >{{ errors.first("domains_quota") }}</span
            >
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2">Monthly Price</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.monthly_renew_price"
              type="text"
              id="monthly_renew_price"
              name="monthly_renew_price"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('monthly_renew_price')"
              >{{ errors.first("monthly_renew_price") }}</span
            >
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="card-text my-1">
            <span for="fname" class="font-weight-bold my-2">Annual Price</span>
          </div>

          <div class="card-text my-1">
            <b-form-input
              autocorrect="off"
              autocomplete="off"
              v-model="result.annually_renew_price"
              type="text"
              id="annually_renew_price"
              name="annually_renew_price"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('annually_renew_price')"
              >{{ errors.first("annually_renew_price") }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import Settings from "@/store/Settings.js";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageLoader,
    Multiselect,
  },
  data() {
    return {
      isDropdownOpen: false,
      labelsList: [
        "CEO",
        "Partner",
        "Internal Staff",
        "Beta User",
        "Testing Account",
        "User",
      ],
      result: {
        expiry_date: "",
        manager_name: "",
        manager_email: "",
        manager_password: "",
        domains_quota: "",
        monthly_renew_price: "",
        annually_renew_price: "",
        extra_info: {},
        labels: [],
        jurisdiction_summarizing: "",
        statement: "",
        case_summarize: "",
        contract_review: "",
        legal_argument: "",
        lod: "",
        summarize_lod: "",
        qbot_questions: "",
        contract_improvments: "",
        legal_argument_documents: "",
        legal_argument_summarize_discussion_case: "",
        legal_argument_summarize_document: "",
        legislation: "",
        legislation_summarizing: "",
        les: "",
        les_trial: "",
      },
      address_1: "",
      address_2: "",
      address_3: "",
      isRemove: false,
      pageLoading: true,
    };
  },
  computed: {
    minDate() {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day
      return currentDate;
    },
  },
  methods: {
    onDropdownOpen() {
      this.isDropdownOpen = true;
    },

    onDropdownClose() {
      this.isDropdownOpen = false;
    },

    AddData(selectedItem) {
      this.result.label.push(selectedItem);
    },

    removeData(index) {
      this.result.label.splice(index, 1);
    },

    editRole() {
      this.result.extra_info.address =
        this.address_1 + "\n" + this.address_2 + "\n" + this.address_3;

      // console.log("Result: ", this.result);
      Settings.EditClient(this.$route.params.id, this.result)
        .then((response) => {
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelUpdate() {
      // Method to handle canceling the updates
    },
  },

  created() {
    Settings.GetClient(this.$route.params.id)
      .then((response) => {
        this.result = response.data.data;

        // console.log(this.result.nexlaw_credits.full);

        this.result = {
          expiry_date: this.result.expiry_date,
          manager_name: this.result.manager_name,
          manager_email: this.result.manager_email,

          domains_quota: this.result.domains_quota,
          monthly_renew_price: 0,
          annually_renew_price: 0,
          labels: this.result.labels,
          extra_info: this.result.extra_info,
          jurisdiction_summarizing:
            this.result.nexlaw_credits.full.jurisdiction_summarizing,
          statement: this.result.nexlaw_credits.full.statement,
          case_summarize: this.result.nexlaw_credits.full.case_summarize,
          contract_review: this.result.nexlaw_credits.full.contract_review,
          legal_argument: this.result.nexlaw_credits.full.legal_argument,
          lod: this.result.nexlaw_credits.full.lod,
          summarize_lod: this.result.nexlaw_credits.full.summarize_lod,
          qbot_questions: this.result.nexlaw_credits.full.qbot_questions,
          contract_improvments:
            this.result.nexlaw_credits.full.contract_improvments,
          legal_argument_documents:
            this.result.nexlaw_credits.full.legal_argument_documents,
          legal_argument_summarize_discussion_case:
            this.result.nexlaw_credits.full
              .legal_argument_summarize_discussion_case,
          legal_argument_summarize_document:
            this.result.nexlaw_credits.full.legal_argument_summarize_document,
          legislation_summarizing:
            this.result.nexlaw_credits.full.legislation_summarizing,
          legislation: this.result.nexlaw_credits.full.legislation,
          les: this.result.nexlaw_credits.full.les,
          les_trial: this.result.nexlaw_credits.full.les_trial,
        };

        if (this.result.labels !== null) {
          this.result.labels = this.result.labels.filter(
            (label) => label !== null
          );
        }

        if (this.result.extra_info.address) {
          if (this.result.extra_info.address !== null) {
            const addressParts = this.result.extra_info.address.split("\n");
            const modifiedAddressParts = addressParts.map((part) =>
              part.includes("undefined") ? "" : part
            );

            this.address_1 = modifiedAddressParts[0];
            this.address_2 = modifiedAddressParts[1];
            this.address_3 = modifiedAddressParts[2];
          }
        }

        this.pageLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.container_margin {
  padding: 2rem;
}

.td-underline {
  text-decoration: underline;
}

.input-address {
  margin: 1rem 0rem;
}
</style>
