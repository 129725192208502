import axios from "@/axios.js";
/* eslint-disable */
export default {
  activateClient(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw-client/${id}/activate`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deactivateClient(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`nexlaw-client/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addClient(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw-client`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetClients(query = "") {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw-client${query}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // GetClients(obj) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`nexlaw-client` + obj)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  // GetClients() {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`nexlaw-client`)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  GetClient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw-client/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  EditClient(id, value) {
    return new Promise((resolve, reject) => {
      axios
        .put(`nexlaw-client/${id}`, value)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
