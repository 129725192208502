<!-- 
  Template:

  -----------------------------------------------------------

  for normal loading / only 1 pageLoading var:
  <PageLoader
    v-if="pageLoading" // optional, required if "v-else-if/v-else" is used for showing content
    :loading="pageLoading"> 
  <PageLoader>

  -----------------------------------------------------------
  
  for loading with duration display / which also have normal loading after the content is generated:
  <PageLoader
    v-if="pageLoading || duration < 100"

    :loading="pageLoading"
    
    :duration="duration"
    message="<...Kindly refer to later section...>">
  <PageLoader>
  
  # the duration must be -1 when generation of the content is completed
    or else the normal loading will not be displayed

  -----------------------------------------------------------

  Optional Fields:

  <PageLoader
  
    // replace "Kindly wait for ..." message in normal loading
    replace_msg="String"

    // replace "Kindly wait for ..." message in normal loading
    // & display the "Kindly wait for ..." with a <small> after the extra_msg
    extra_msg="String"

    // defined message to be displayed in duration loading
    // input String will lead to using default messages
        ## refer to vue data() for default messages ##
    // input Array that contains 2 Strings will result in customized message
        ## the first String is normal sized / the second String is <small> ##
    message="String | Array[2]"

    >
  <PageLoader>

 -->
<template>
  <div
    v-if="duration >= 0 && duration < 100"
    class="card border-0 h-100 p-3 rounded text-center justify-content-center"
  >
    <div>
      <div style="transform: scale(2)" class="orbit-spinner m-auto">
        <div class="orbit"></div>
        <div class="orbit"></div>
        <div class="orbit"></div>
      </div>

      <div class="row mt-5" v-if="duration >= 0">
        <div class="col">
          <h3 class="font-weight-bold text-blue">{{ duration }} %</h3>
        </div>
      </div>

      <div :class="['row', duration < 0 ? 'mt-5' : 'mt-3']">
        <div class="col">
          <h4 class="text-blue text-center font-weight-bold">
            {{ Array.isArray(message) ? message[0] : default_msg[message][0] }}
          </h4>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-3"></div>
        <div class="col">
          <small class="text-blue text-center">
            {{ Array.isArray(message) ? message[1] : default_msg[message][1] }}
          </small>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>

  <div v-else-if="loading" class="h-100 align-content-center">
    <div>
      <div class="orbit-spinner m-auto mt-5" style="transform: scale(2)">
        <div class="orbit"></div>
        <div class="orbit"></div>
        <div class="orbit"></div>
      </div>
      <p class="text-center text-blue font-weight-bold mt-5">
        {{ extra_msg ?? replace_msg ?? "Kindly wait for a few seconds" }}
      </p>
      <p class="text-center">
        <small v-if="extra_msg" class="text-blue">
          Kindly wait for a few seconds
        </small>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
    },
    replace_msg: {
      type: String,
    },
    extra_msg: {
      type: String,
    },

    duration: {
      type: Number,
    },
    message: {
      type: [String, Array],
    },
  },

  data() {
    return {
      default_msg: {
        generating: [
          "Generating Content",
          "Please wait a moment while we process it. This may take a few seconds.",
        ],
        generatingLongerContent: [
          "Generating Content",
          "Please wait a moment while we process it. This may take 2 to 3 minutes.",
        ],
        uploading: [
          "Uploading case-law.pdf",
          "Please wait a moment while we process it. This may take some time depending on the size the file and your internet connection speed.",
        ],
      },
    };
  },
};
</script>

<style scoped>
/* orbitter */
.orbit-spinner,
.orbit-spinner * {
  box-sizing: border-box;
}

.orbit-spinner {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  perspective: 800px;
}

.orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
  border-bottom: 3px solid #094890;
}

.orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
  border-right: 3px solid #094890;
}

.orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
  border-top: 3px solid #094890;
}

@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
</style>
